import React from "react";
import Link from "../../utils/link";
import * as styles from "./pub.module.scss";

function intersperse(arr, sep, last) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce(
    function (xs, x, i) {
      if (i < arr.length - 2) {
        return xs.concat([sep, x]);
      } else {
        return xs.concat([last, x]);
      }
    },
    [arr[0]]
  );
}

export default function Publication({
  title,
  authors,
  links,
  bibtex,
  date,
  type,
  conference,
}) {
  var authors_ = authors.map((author) => (
    <span key={author.name} className={styles["feed__itemAuthor"]}>
      {author.website ? (
        <Link to={author.website}>{author.name}</Link>
      ) : (
        <span>{author.name}</span>
      )}
    </span>
  ));
  authors_ = intersperse(authors_, ", ", " and ");

  var links_ = links.map((link) => (
    <span key={link.url} className={styles["feed__itemMetaLink"]}>
      <Link to={link.url}>{link.name}</Link>
    </span>
  ));
  var conf = "";
  if (conference) {
    if (conference.url) {
      conf = <Link to={conference.url}>{conference.name}</Link>;
    } else {
      conf = <span>{conference.name}</span>;
    }
  }
  links_ = intersperse(links_, " / ", " / ");
  return (
    <div className={styles["feed__item"]} key={title}>
      <time
        className={styles["feed__itemMetaTime"]}
        dateTime={new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      >
        {new Date(date).toLocaleDateString("en-US", {
          year: "numeric",
          month: "long",
        })}
      </time>
      <span className={styles["feed__itemMetaDivider"]}> &mdash; </span>
      <span className={styles["feed__itemMetaCategory"]}>
        {type}
        {conference && ", "}
        {conf}
      </span>
      <br />
      {authors_}
      <p className={styles["feed__itemTitle"]}>{title}</p>
      {links_}
    </div>
  );
}
