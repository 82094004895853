// @flow strict
import React from "react";
import { graphql } from "gatsby";
import { withPrefix, Link } from "gatsby";
import Layout from "../components/Layout";
import Sidebar from "../components/Sidebar";
import Page from "../components/Page";
import useSiteMetadata from "../utils/use-site-metadata";
import { MDXRenderer } from "gatsby-plugin-mdx";
import Publication from "../components/Publication";
import { getContactHref, getIcon } from "../utils";
import { StaticImage } from "gatsby-plugin-image";

import {
  Timeline,
  Container,
  YearContent,
  BodyContent,
  Section,
  Description,
} from "vertical-timeline-component-react";

const customTheme = {
  yearColor: "#405b73",
  lineColor: "#d0cdc4",
  dotColor: "#262626",
  borderDotColor: "#d0cdc4",
  titleColor: "#405b73",
  subtitleColor: "#bf9765",
  textColor: "#262626",
};

const CV = ({ data }) => {
  const { title: siteTitle, subtitle: siteSubtitle } = useSiteMetadata();
  const metaDescription = siteSubtitle;

  return (
    <Layout title="Resume">
      <Sidebar isIndex />
      <Page title="Resume">
        You can download a PDF Version <a href="/pdf/CV_clement_pinard_2023.pdf">Here</a>
        <h1> Education </h1>
        <Timeline theme={customTheme} dateFormat="ll">
          <Container>
            <YearContent startDate="2016" endDate="2019" />
            <BodyContent>
              <Section title="PhD Computer Vision - ENSTA Paris">
                <StaticImage src="./ensta.png" alt="ENSTA logo" width={100} />
                <p>
                  Supervised by{" "}
                  <a href="https://perso.ensta-paris.fr/~manzaner/">
                    Antoine Manzanera
                  </a>
                  <br />
                  <i>
                    Robust Learning of a depth map for obstacle avoidance with a
                    monocular stabilized flying camera
                  </i>
                  <br />
                  See <Link to="/phd_thesis/"> dedicated page </Link> <br />
                  PhD awarded on June 2019
                </p>
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2011" endDate="2015" />
            <BodyContent>
              <Section title="Master of Engineering - CentraleSupélec">
                <StaticImage src="./CS.png" alt="CS logo" width={100} />
                <Description text="Specialized in Electronic Systems, Networks & Images" />
              </Section>
            </BodyContent>
          </Container>
        </Timeline>
        <h1> Work Experience </h1>
        <Timeline theme={customTheme} dateFormat="ll">
        <Container>
            <YearContent startDate="2022/04" />
            <BodyContent>
              <Section title="XXII - Puteaux, France">
                <StaticImage
                  src="./xxii.png"
                  alt="XXII logo"
                  width={100}
                />
                <Description variant="subtitle" text="Computer Vision Scientist" />
                <Description text="Computer Vision for security cameras" />
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2021/07" endDate="2022/04" />
            <BodyContent>
              <Section title="ContentSquare - Paris, France">
                <StaticImage
                  src="./contentsquare.png"
                  alt="Contentsquare logo"
                  width={100}
                />
                <Description variant="subtitle" text="Data Scientist" />
                <Description text="Web page contextualization for automatic page and html elements classification" />
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2021/01" endDate="2021/06" />
            <BodyContent>
              <Section title="Upstride (acquired by ContentSquare) - Paris, France">
                <StaticImage
                  src="./upstride.png"
                  alt="Upstride logo"
                  width={100}
                />
                <Description
                  variant="subtitle"
                  text="Deep Learning Researcher"
                />
                <Description text="Applying Geometric Algebra on convolutional neural networks for sematic segmentation" />
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2019/07" endDate="2020/12" />
            <BodyContent>
              <Section title="ENSTA Paris - Palaiseau, France">
                <StaticImage src="./ensta.png" alt="ENSTA logo" width={100} />
                <Description variant="subtitle" text="Research Engineer" />
                Working on Rigid Depth Constructor. Constructing a dataset for
                depth map evaluation in the context of obstacle avoidance for a
                monocular stabilized flying camera. <br />
                See <Link to="/rigid_depth_constructor/"> dedicated page </Link>
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2016/01" endDate="2019/06" />
            <BodyContent>
              <Section title="Parrot - Paris, France">
                <StaticImage src="./PARROT.png" alt="PARROT logo" width={100} />
                <Description variant="subtitle" text="Cifre PhD Student" />
                <Description text="CIFRE Phd Done with PARROT and ENSTA." />
                <i>
                  Robust Learning of a depth map for obstacle avoidance with a
                  monocular stabilized flying camera.
                </i>
                <br />
                See <Link to="/phd_thesis/"> dedicated page </Link>
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2015/03" endDate="2015/09" />
            <BodyContent>
              <Section title="Parrot - Paris, France">
                <StaticImage src="./PARROT.png" alt="PARROT logo" width={100} />
                <Description
                  variant="subtitle"
                  text="Computer Vision Intern "
                />
                <Description
                  text="Designing and implementing an algorithm for
                  vision-based localization with a known target for embedded systems"
                />
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2014/02" endDate="2014/06" />
            <BodyContent>
              <Section title="Technip - Kuala Lumpur, Malaysia">
                <StaticImage src="./T.png" alt="Technip logo" width={100} />
                <Description
                  variant="subtitle"
                  text="Knowledge Management Intern"
                />
                <Description
                  text="Designing and administrating a knowledge 
                  management platform tool for project managers, from tender to product shipping"
                />
              </Section>
            </BodyContent>
          </Container>
          <Container>
            <YearContent startDate="2013/07" endDate="2014/01" />
            <BodyContent>
              <Section title="Civolution, now part of Kudelski Group - Rennes, France">
                <StaticImage src="./Ci.png" alt="Civolution logo" width={100} />
                <Description
                  variant="subtitle"
                  text="Software Engineering Intern"
                />
                <Description text="Modifying x264 encoder to include Nexguard Watermarking plugin within the encoding process" />
              </Section>
            </BodyContent>
          </Container>
        </Timeline>
      </Page>
    </Layout>
  );
};

export const query = graphql`
  query SlugHome2 {
    allResearchYaml(sort: { fields: [date], order: DESC }) {
      nodes {
        id
        title
        date(formatString: "YYYY")
        bibtex
        authors {
          name
          website
        }
        links {
          name
          url
        }
        conference {
          name
          url
        }
      }
    }
  }
`;

export default CV;
