// extracted by mini-css-extract-plugin
export var feed__item = "pub-module--feed__item--ZBuB8";
export var feed__itemAuthor = "pub-module--feed__item-author--TVjRu";
export var feed__itemDescription = "pub-module--feed__item-description--i0AVN";
export var feed__itemMetaCategoryLink = "pub-module--feed__item-meta-category-link--4CPAg";
export var feed__itemMetaDivider = "pub-module--feed__item-meta-divider--6Nhn4";
export var feed__itemMetaLink = "pub-module--feed__item-meta-link--tbjG5";
export var feed__itemMetaTime = "pub-module--feed__item-meta-time--zVcD9";
export var feed__itemReadmore = "pub-module--feed__item-readmore--496Jx";
export var feed__itemTitle = "pub-module--feed__item-title--KcZKZ";
export var feed__itemTitleLink = "pub-module--feed__item-title-link--m+c6D";